.cardPaddiingView{
    padding-top:50px;
}

.searchFilter {
    color: white;
    background-color: #117a8b;
    border-color: #10707f;
    border-Radius: 5px;
    padding: 20px 22px;
    margin-bottom: 65px;
    margin-top: -65px;
}
.inputField {
    outline: 0;
    border-width: 0 0 1px; 
    border-color: violet; 
    border-radius: 0;
}
.inputField2 {
    outline: 0;
    border-width: 0 0 2px;
    border-color: violet;
    border-radius: 0;
}
.inputFieldDisable {
    outline: 0;
    border-width: 0 0 0px;
    border-radius: 0;
} 
.header-title {
    margin-bottom: 35px
}
.header-title h4 {
        margin: 0px;
    }

.title1 i {
        background: #007bff;
        color: white;
        padding: 13px;
        /* margin: -2px; */
        margin-right: 20px;
        border-radius: 4px;
    }

.title2 i {
    background: #28a745;
    color: white;
    padding: 13px;
    /* margin: -2px; */
    margin-right: 20px;
    border-radius: 4px;
}

.title3 i {
    background: #87cb16;
    color: white;
    padding: 13px;
    /* margin: -2px; */
    margin-right: 20px;
    border-radius: 4px;
}

.title4 i {
    background: #00bbff;
    color: white;
    padding: 13px;
    /* margin: -2px; */
    margin-right: 20px;
    border-radius: 4px;
}

.newBtn a {
    float: right;
    font-size: 17px;
    border: 1px solid;
    margin-top: 20px;
}
.newBtn i {
    color: brown;
    font-size: 18px;
   
}

.print {
    color: white;
    font-size: 28px;
    text-align: center;
}

.btnRound {
    height: 50px;
    line-height: 50px;
    width: 50px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #3B9C64;
    text-align: center;
    cursor: pointer;
    padding: 8px;
    border: none;
    margin-bottom: -25px;
    margin-top: -20px;
}

.btnRound:hover {
        background-color: #3B9C64 !important;
    }