
.margin-sm-top-50 {
    margin-top: 50px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
}
.sidebar .logo, body > .navbar-collapse .logo {
    padding: 30px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

    .sidebar .logo a.logo-mini img{
        width: 100px;
        display: block;
        margin-top: -25px;
        margin-left: 5px;
    }

    .sidebar .nav p {
        margin: 0;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
        text-transform: none !important;
    }
.sidebar.logout {
    float: right;
    margin-top: -32px;
    margin-right: -17px;
    font-family: sans-serif;
    font-weight: 700;
}
.logout-drop{
    position:relative;
    display:inline-block;
    font-size:30px;
    font-weight:800;
}
.logout-drop:hover {
    color: red;
}
.logout-content {
    display:none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   
    z-index: 1;
    margin-left: -134px;
    cursor:pointer;
}
    .logout-content p {
        color: black;
        cursor: pointer;
        padding: 12px 16px;
    }
    .logout-content p:hover{
        background:rgba(0,0,0,0.2);
    }
    .logout-drop:hover .logout-content {
        display: block;
    }

@media only screen and (min-width: 768px) {
    .margin-lg-top-150 {
        margin-top: 150px;
    }
}

.input-box {
    outline: 0;
    borderWidth: "0 0 1px";
    borderRadius: 0;
    borderColor: "violet"
}
