
body {
}
.cardPaddiingAccounDetails {
    padding-top: 50px;
}
.searchFilter {
    color: white;
    background-color: #117a8b;
    border-color: #10707f;
    border-Radius: 5px;
    padding: 20px 22px;
    margin-bottom: 65px;
    margin-top: -65px;
}
.invoice-section {
    margin-top: 15px;
}
view-quotation {
    margin-top: 20px;
}
.header-title{
    margin-bottom:35px
}
.header-title h4{
    margin:0px;
}
.newBtn a {
    float: right;
    font-size: 17px;
    border: 1px solid;
    margin-top: 20px;
}

.header-title i {
    font-size: 30px;
    background: #3b9ce6;
    color: white;
    padding: 13px;
    /* margin: -2px; */
    margin-right: 20px;
    border-radius: 4px;
}

.quotation-section {
    margin-top: 25px;
}
.btn_icon {
    padding: 0px 5px;
    margin-bottom: 43px;
    margin-top: -24px;
}
.inputField {
    outline: 0;
    border-width: 0 0 2px;
    border-color: violet;
    border-radius: 0;
}

.inputFieldDisable {
    outline: 0;
    border-width: 0 0 0px;
    border-radius: 0;
} 
.btn-action {
    border-radius: 50%;
    padding: 3px 6px;
    opacity: .50;
    margin: 3px;
    border: 2px solid #ff6a00;
}
    .btn-action i {
        font-size: 14px;
        color: #ff6a00;
    }
.action-delete {
    border-radius: 50%;
    padding: 3px 6px;
    opacity: .50;
    margin: 3px;
    border: 2px solid red;
}

    .action-delete i {
        font-size: 14px;
        color: red;
    }