body {
}

{/*salary Entry css*/}
.top-border-line {
    border-top-width: 2px;
    border-color: purple;
}



{/*cash or bank recipts view*/}



.recipts-top-icon-box {
    color: white;
    background-color: #117a8b;
    border-radius: 5px;
    padding: 22px 25px;
    margin-bottom: 65px;
    margin-top: -65px;
}


.recipts-viex-input-sort {
    outline: 0;
    border-width: 0 0 1px 0;
    border-color: purple;
    font-size: 9px;
    width: 85%;
}
.btn-edit-icon {
    border-radius: 200px;
    padding: 3px 6px;
    opacity: .50; 
}
.btn-delet-icon {
    border-radius: 200px;
    padding: 3px 6px;
    opacity: .50;
}
.btn-edit-icon i {
    font-size: 14px; 
    color: red;
}
.custom-td {
    border-top: 0px; 
    font-size: 10px;
}
.btn-delet-icon i {
    font-size: 14px;
    color: red;
}

{/*payments*/}
.payment-btn-top-icon {
    padding: 22px 38px 22px 25px;
    margin-bottom: 65px;
    margin-top: -65px;
    color: white;
    background-color: #117a8b;
    border-radius: 5px;
}
.pay-method {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-radius: 0px;
    width: 100%; 
    border-color: violet;
    font-size:12px;
}
.pay-amount-input {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    font-size: 12px;
    width: 100%;
}
.pay-account-input {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    font-size: 12px;
    width: 100%;
}
.pay-bankname-input {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    font-size: 12px;
    width: 100%;
}
.pay-branch-input {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    font-size: 12px;
    width: 100%;
}
.pay-via-method {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    font-size: 12px;
    border-radius: 0px;
    color: #ed0909;
    margin-left: 20%;
    width:32%
    
}
.pay-mobile-number {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    border-radius: 0px;
    font-size: 12px;
    width: 100%;
    padding: 5px 12px;
}

.pay-account-number {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    border-radius: 0px;
    font-size: 12px;
    width: 100%;
    padding: 5px 12px;
}



{/*job card css*/}
.jobcard-top-icon {
    padding: 22px 25px;
    margin-bottom: 65px;
    margin-top: -65px;
    background-color: #117a8b;
    color: white;
    border-radius: 5px;
}
.top-title {
    margin-bottom:20px;
    margin-top:-20px
}
.job-card-no-btn-first {
    padding: 2px 7px;
    font-size: 11px
}

{/*Quotation Service and parts*/}
.Quotation-top-icon {
    padding: 22px 25px;
    margin-bottom: 65px;
    margin-top: -65px;
    background-color: #117a8b;
    color: white;
    border-radius: 5px;
}
.services-parts-input {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    border-radius: 0px;
    font-size: 12px;
    width: 100%;
    padding: 5px 12px;
}
.th-service-header {
    border-bottom: 2px solid violet;
    font-size: 12px;
}

{/*Payments Input*/}

.payment-top-icon {
    padding: 22px 25px;
    margin-bottom: 65px;
    margin-top: -65px;
    background-color: #117a8b;
    color: white;
    border-radius: 5px;
}

{/*cost price*/}
.cost-price-icon-box {
    padding: 17px 20px;
    margin-bottom: 65px;
    margin-top: -46px;
    background-color: #02d3f5;
    color: white;
    border-radius: 5px;
}