body {
    margin-left: 15px;
    margin-right: 15px;
}

table {
    page-break-inside: auto
}

tr {
    page-break-inside: avoid;
    page-break-after: auto
}

.tblInvTop {
    font-size: 12px;
}

    .tblInvTop td:nth-child(odd) {
        width: 120px;
    }

    .tblInvTop td:nth-child(even) {
        border: 1px solid black;
        text-align: center;
        width: 150px;
    }

.tblInvAddr {
    font-size: 12px;
    width: 100%;
    margin-top: 20px;
}

.erp-table-head th {
    padding-left: 15px;
    background-color: #eef4ff;
    -webkit-print-color-adjust: exact;
    color: #000c35;
    font-weight: bold;
    border: solid 1px black;
}
.erp-table-head td {
    padding-left: 5px;
}
    .erp-table-header {
    background-color: #81a1bf;
    -webkit-print-color-adjust: exact;
    color: white;
    font-weight: bold;
}

    .erp-table-header th {
        text-align: center;
    }

.service-header {
    background-color: #eef4ff;
    -webkit-print-color-adjust: exact;
    color: black;
    font-weight: bold;
    padding: 5px;
    margin-top: 6px;
}

.tblSalesPerson {
    font-size: 12px;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    border: 0 solid gray;
    border-spacing: 0;
}

    .tblSalesPerson td {
        border: 1px solid black;
    }

.tblData {
    width: 100%;
    font-size: 12px;
    border: 0 solid gray;
    border-spacing: 0;
    margin-top: -11px;
}

    .tblData td {
        border-left-color: black;
        border-left-style: solid;
        border-left-width: 1px;
        border-bottom-color: gray;
        border-bottom-style: dotted;
        border-bottom-width: 1px;
    }

        .tblData td:last-child {
            border-right-color: black;
            border-right-style: solid;
            border-right-width: 1px;
        }

.tblDataTotal td {
    background-color: lightgray;
    -webkit-print-color-adjust: exact;
    color: black;
    font-weight: bolder;
    font-size: 14px;
}

.tblDue {
    margin-top: 20px;
    width: 335px;
}

    .tblDue td {
        border: 1px solid black;
    }

.tblCmnts {
    page-break-inside: avoid;
    margin-top: 20px;
    font-size: 12px;
}

    .tblCmnts td {
        border: 1px solid black;
    }

.table-td-wid {
    width: 354px;
    word-break: break-all;
    white-space: normal;
    padding: 4px;
}

.th-td-center {
    text-align: center;
}

@media print {
    .tblCont {
        position: fixed !important;
        bottom: 0px;
        width: 100%;
    }
}

.tblCont {
    /*position: fixed !important;*/
    bottom: 120px;
    width: 100%;
}

/*@media (page: 1) {
            .tblCont {
                position: fixed !important;
                bottom: 10px;
                width: 100%;
            }

        }*/

.tblFooter {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
}

    .tblFooter hr {
        width: 70%;
    }

@media print {
    table {
        page-break-inside: auto
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    @page {
        size: A4; /* DIN A4 standard, Europe */
        margin: 130px 1cm 0px 1cm;

        @bottom-right {
            content: counter(page) " of " counter(pages);
        }
    }

}

p {
    text-transform: uppercase;
    margin-bottom: -2px;
    font-size: 14px;
}
.txtEllips {
    text-overflow: ellipsis;
}
