body {
}
.payment-row {
    margin-right: -10px;
    margin-left: -16px;
}
.payment-top-icon-box {
    color: white;
    background-color: #117a8b;
    border-radius: 5px;
    padding: 22px 25px;
    padding-right: 34px;
    margin-bottom: 65px;
    margin-top: -65px;
}

.payment-input-dropdown {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    font-size: 16px;
    width: 100%;
    border-radius: 0px;
}

.input-bottom-border {
    outline: 0px;
    border-width: 0 0 1px 0;
    border-color: violet;
    font-size: 16px;
    width: 100%;
}

.payment-text-area-fild {
    outline: 0px;
    border-color: violet;
    border-radius: 0px;
    width: 100%;
}

.th-tr {
    font-size: 12px;
}

.tbody-tr {
    font-size: 10px;
}

.btn-submit {
    border: none;
    float: right;
    padding: 12px 41px;
}

.cardPaddiing {
    padding-top: 50px;
}

.titleMargin {
    color: white;
    background-color: #117a8b;
    border-color: #10707f;
    border-Radius: 5px;
    padding: 20px 20px;
    margin-bottom: 65px;
    margin-top: -65px;
}

.header-title {
    margin-bottom: 35px
}

    .header-title h4 {
        margin: 0px;
    }

.title-bank i {
    font-size: 30px;
    background: #117a8b;
    color: white;
    padding: 13px;
    margin-right: 20px;
    border-radius: 4px;
}

.newBtn a {
    float: right;
    font-size: 17px;
    border: 1px #117a8b solid;
    margin-top: 20px;
}

.newBtn i {
    color: brown;
    font-size: 18px;
}
.print {
    color: white;
    font-size: 28px;
    text-align: center;
}

.btnRound {
    height: 50px;
    line-height: 50px;
    width: 50px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #8c000d;
    text-align: center;
    cursor: pointer;
    padding: 8px;
    border: none;
    margin-bottom: -25px;
}

    .btnRound:hover {
        background-color: #3B9C64 !important;
    }



