body {
}

.background-Color-nop{
    background:none !important;
}
.border-nop{
    border:none !important;
}

.m-t-10 {
    margin-top: 10px !important;
}
.m-t-40{
    margin-top:40px !important;
}
.m-b-20{
    margin-bottom:20px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}
.ml-mins-13 {
    margin-left: -13px !important;
}
/*padding*/
.p-t-35{
    padding-top:35px !important;
}
.p-b-50{
    padding-bottom:50px !important;
}
.p-0{
    padding:0px !important;
}
.pl-3{
    padding-left:3px !important;
}
.pr-15{
    padding:0px 15px 0px 0px;
}
.p-r-15 {
    padding-right: 15px !important;
}

.pt-30{
    padding-top:30px;
}


.fnt-15{
    font-size:15px;
}
.fnt-26{
    font-size:26px;
}
.fnt-24{
    font-size:24px;
}
.fnt-28{
      font-size:28px;
}
.fnt-32{
    font-size:32px;
}
.fnt-37{
    font-size:37px;
}

/*Font weight*/

.fw-3{
    font-weight:300
}
.width-3hnd-fix{
    width:300px;
}

/*Input Field*/
.custom-input-purple {
    border-color: violet;
    outline: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
    background-color: #ffffff59;
}
    .custom-input-purple:focus {
        border-color: violet !important;
        border-radius: 9px !important;
    }
.custom-input-blue {
    border-color: blue;
    outline: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
    background-color: #ffffff59;
}

    .custom-input-blue:focus {
        border-color: blue !important;
        border-radius: 9px !important;
    }
    /*suppliers Entry css*/
    .suppliersTitle {
        font-family: inherit;
    }
.sub-title {
    color: #448dd5e6;
}

.title-line1 {
    width: 212px;
    background: #23232369;
    height: 2px;
}

.title-line2 {
    width: 135px;
    background: #23232369;
    height: 2px;
    margin-top: 4px;
}

.suppl-vart-line {
    border-left: 2px solid #55acee91;
    vertical-align: middle;
    margin-left: 53px;
    height: 324px;
    margin-top: 143px;
    margin-bottom: 6%;
}
@media screen and (max-width:700px){
    div.suppl-vart-line{
        display:none;

    }
    /*Employ profile*/
    div.employ-profile-varticalLine{
        display:none;
    }
    
    .card .title{
        font-size:15px;
    }
    .card .icon{
        font-size:22px;
    }
    .card .lh-8{
        line-height:8px;
    }
   
    div.BnkAccDetails {
        padding-top: 60px !important;
    }
    div.column-saperator {
        background-color: #6c757d14;
        height: 2px;
        /* padding-top: 15px; */
        margin-top: 70px;
    }
    div.stChild {
        padding-right: 0px !important;
    }
    div.employ-profile1{
        width: 100%;
    }
    div.employ-profile2{
        width:60%;
    }
}
@media screen and (max-width:900px){
    .card label {
        font-size: 10px !important;
    }
}
@media screen and (max-width:1280px) {
    div.suppl-vart-line {
        margin-left: 43px;
    }

}
/*@media screen and (max-width:1500px){
    
}*/


@media screen and (min-width: 700px) {
    /* For desktop: */
   
    .col-md-1 {
        width: 8.33%;
    }

    .col-md-2 {
        width: 16.66%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33%;
    }

    .col-md-5 {
        width: 41.66%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33%;
    }

    .col-md-8 {
        width: 66.66%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33%;
    }

    .col-md-11 {
        width: 91.66%;
    }

    .col-md-12 {
        width: 100%;
    }
}
@media screen and (min-width: 760px) {
    /* For desktop: */
    .col-md-1 {
        width: 8.33%;
    }

    .col-md-2 {
        width: 16.66%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33%;
    }

    .col-md-5 {
        width: 41.66%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33%;
    }

    .col-md-8 {
        width: 66.66%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33%;
    }

    .col-md-11 {
        width: 91.66%;
    }

    .col-md-12 {
        width: 100%;
    }
}
@media screen and (min-width: 900px) {
    /* For desktop: */
    .col-md-1 {
        width: 8.33%;
    }

    .col-md-2 {
        width: 16.66%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33%;
    }

    .col-md-5 {
        width: 41.66%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33%;
    }

    .col-md-8 {
        width: 66.66%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33%;
    }

    .col-md-11 {
        width: 91.66%;
    }

    .col-md-12 {
        width: 100%;
    }
}
@media scree and (min-width: 900px) {
    .card label {
        font-size: 12px;
    }
}
@media screen and (min-width: 1280px) {
    /* For desktop: */
    /* For desktop: */
    div.suppl-vart-line {
        margin-left: 40px;
    }
    .card label {
        font-size: 14px;
    }
    div.suppl-vart-line {
        margin-left: 53px;
    }
    .col-md-1 {
        width: 8.33%;
    }

    .col-md-2 {
        width: 16.66%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33%;
    }

    .col-md-5 {
        width: 41.66%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33%;
    }

    .col-md-8 {
        width: 66.66%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33%;
    }

    .col-md-11 {
        width: 91.66%;
    }

    .col-md-12 {
        width: 100%;
    }
}
@media screen and (min-width:1800px) {
    div.suppl-vart-line {
        margin-left: 53px;
    }
}
@media screen and (min-width: 2200px) {
    /* For desktop: */
    div.suppl-vart-line {
        margin-left: 60px;
    }

    .col-md-1 {
        width: 8.33%;
    }

    .col-md-2 {
        width: 16.66%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33%;
    }

    .col-md-5 {
        width: 41.66%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33%;
    }

    .col-md-8 {
        width: 66.66%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33%;
    }

    .col-md-11 {
        width: 91.66%;
    }

    .col-md-12 {
        width: 100%;
    }
}



/*Employee Profile Css*/
.employProfileTitle{
    font-family:inherit;

}

/*EmployProfile*/
.employ-profile1 {
    width: 234px;
    background: #23232369;
    height: 2px;
}

.employ-profile2 {
    width: 130px;
    background: #23232369;
    height: 2px;
    margin-top: 3px;
}

.employ-profile-varticalLine {
    border-left: 2px solid #ee82ee2e;
    vertical-align: middle;
    /* margin-left: 0px; */
    height: 159px;
    margin-top: 100px;
    /* margin-bottom: 20%;*/
}

/*Client*/
.client-title-line1 {
    width: 175px;
    background: #23232369;
    height: 2px;
}
.client-title-line2 {
    width: 100px;
    background: #23232369;
    height: 2px;
    margin-top: 3px;
}
.line-container {
    padding-bottom: 50px;
}

.line1 {
    width: 150px;
    background: #23232369;
    height: 2px;
}

.line2 {
    width: 85px;
    background: #23232369;
    height: 2px;
    margin-top: 3px;
}




/* Expense*/

.expense-card {
    border: 1px solid #2323;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .15);
    padding-top: 30px;
    padding-bottom: 30px;
}
.expense-line-1 {
    width: 182px;
    background: #23232369;
    height: 3px;
}
.expense-line-2 {
    width: 86px;
    background: #23232369;
    height: 3px;
    margin-top: 4px;
}
/*owners Equity css*/
.owner-equity-card {
    border: 1px solid #2323;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .15);
}
.btn-save {
    background-color: #42d0ed !important;
    border-color: #42d0ed !important;
    /*padding: 7px 50%;*/
    box-shadow: 2px 0.5rem 1rem rgba(29,199,237,.15) !important;
    font-weight: 700;
    float: right;
}
.ownerEquity-line1 {
    width: 240px;
    background: #23232369;
    height: 2px;
}
.ownerEquity-line2 {
    width: 125px;
    background: #23232369;
    height: 2px;
    margin-top: 3px;
}

/*Bank */

.bank-title-line1 {
    width: 175px;
    background: #23232369;
    height: 2px;
}
.bank-title-line2 {
    width: 100px;
    background: #23232369;
    height: 2px;
    margin-top: 3px;
}
