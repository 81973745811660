body {
}
.card-head-title {
    margin: -12px 0 19px;
}
    .card-head-title i {
        background: #87CB16;
        color: white;
        padding: 16px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 29px;
        margin-right: 11px;
    }
.card-label {
    font-size: 11px;
    color: #9A9A9A;
    margin-top: 11px;
}
.card-title-in {
    font-weight: 400;
    border-bottom: 2px solid #049f0c26;
    font-family: sans-serif;
    padding-bottom: 7px;
    margin-bottom:20px;
}
.margin {
    margin-right: 60px;
}
.reg-input {
    margin-right: -69px;
    margin-left: -1px;
}
.label-text {
    margin-top: 11px;
    font-size: 13px !important;
}
.margin {
    margin-bottom: -9px;
    margin-top: 27px;
}
.radio-btn {
    display: flex;
    margin-top: 34px;
    margin-left: -22px;
}
    .radio-btn label {
        color: black;
        font-family: sans-serif;
        font-weight: 700;
    }
.quotNumber {
    position: absolute;
    right: 74px;
}
.card-filter {
    position: absolute;
    right: 75px;
}
.card-submit button{
    margin-right:45px;
}